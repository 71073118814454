'use strict'

###*
 # @ngdoc service
 # @name mundoMap.factory:MundoMap

 # @description

###
angular
  .module 'mundoMap'
  .factory 'MundoMap', [
    '$window',
    '$log',
    'debounce',
    '$timeout',
    'mundoConfiguration',
    'Restangular',
    '_',
    'DisplayLabel',
    '$http'
    ($window, $log, debounce, $timeout, mundoConfiguration, Restangular, _, DisplayLabel, $http) ->
      ol = $window.ol

      # coffeelint: disable=max_line_length
      # Define Belgian Lambert 72 (EPSG:31370) projection
      proj4.defs "EPSG:31370", "+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.869,52.2978,-103.724,0.3366,-0.457,1.8422,-1.2747 +units=m +no_defs"
      # coffeelint: enable=max_line_length
      MundoMapBase = {}
      MundoMapBase.GeoJSON = new ol.format.GeoJSON
      MundoMapBase.EsriJSON = new ol.format.EsriJSON()

      MundoMapBase.styleCache = {}
      MundoMapBase.styleDefaults = (feature) ->
        #/** @type {Object.<ol.geom.GeometryType, Array.<ol.style.Style>>} */
        style = null
        bgColor = '#F9F9F9'
        textColor = '#0A94D6'
        iconType = 'police-vehicle'
        iconColor = '#CCCCCC'

        activeLabelDisplay = DisplayLabel.getActiveKey()
        opacity= 0.85
        if feature.get('inactive') is true
          opacity= 0.4

        if not style
          style = [
              new ol.style.Style
                image: new ol.style.Icon
                  #/** @type {olx.style.IconOptions} */
                  anchor: [0.5, 0.5]
                  anchorXUnits: 'fraction'
                  anchorYUnits: 'fraction'
                  opacity: opacity
                  src: "/images/map-rectangle/default.png"
                  color: bgColor
                text: new ol.style.Text
                  text: feature.get("label")
                  scale: 1.25
                  offsetY: -20
                  offsetX: 10
                  fill: new ol.style.Fill
                    color: textColor
            ,
              new ol.style.Style
                image: new ol.style.Icon
                  #/** @type {olx.style.IconOptions} */
                  anchor: [2.45, 1.375]
                  anchorXUnits: 'fraction'
                  anchorYUnits: 'fraction'
                  opacity: 0.85
                  src: "/images/map-vehicle/#{iconType}.svg"
                  color: iconColor
          ]

        return style

      MundoMapBase.clusterStyleCache = {}
      MundoMapBase.clusterStyleDefaults = (feature, resolution) ->
        size = feature.get('features').length
        style = null

        if size == 1
          feature = feature.get('features')[0]
          style = feature.get('_cachedStyle')

          if not style
            style = MundoMapBase.styleDefaults feature
            feature.set('_cachedStyle', style)
        else
          style = MundoMapBase.clusterStyleCache[size]

          if not style
            style = [
              new ol.style.Style
                image: new ol.style.Circle
                  radius: 15
                  stroke: new ol.style.Stroke
                    color: '#FFF'
                  fill: new ol.style.Fill
                    color: '#3399CC'
                text: new ol.style.Text
                  text: size.toString()
                  scale: 1.5
                  fill: new ol.style.Fill
                    color: '#FFF'
            ]

            MundoMapBase.clusterStyleCache[size] = style

        return style

      MundoMapBase.layerDefaults =
        _layerType: 'Tile'
        _searchable: false
        _zoomable: false
        preload: 3
        visible: false

      MundoMapBase.defaults =
        layers: [
            _layerId: 'osm_base'
            _weight: 60
            title: 'OSM BE'
            type: 'base'
            visible: true
            source: [
              'OSM'
              url: "https://tile.openstreetmap.be/osmbe/{z}/{x}/{y}.png"
              attributions: [ ol.source.OSM.ATTRIBUTION, "Tiles courtesy of <a href=\"https://geo6.be/\">GEO-6</a>" ]
              maxZoom: 18
            ]
          ,
            _layerId: 'osm'
            title: 'OSM'
            type: 'base'
            source: ['OSM']
          ,
          #   _layerId: 'mapquest_osm'
          #   title: 'MapQuest (OSM)'
          #   type: 'base'
          #   visible: false
          #   source: [
          #     'MapQuest',
          #       layer: 'osm'
          #   ]
          # ,
            _layerId: 'stamen_toner'
            title: 'Stamen (Toner)'
            type: 'base'
            source: [
              'Stamen',
                layer: 'toner-lite'
            ]
          ,
          #   _layerId: 'mapquest_hybrid'
          #   _layerType: 'Group'
          #   title: 'MapQuest (Aerial hybrid)'
          #   type: 'base'
          #   layers: [
          #       source: [
          #         'MapQuest'
          #           layer: 'sat'
          #       ]
          #     ,
          #       source: [
          #         'MapQuest'
          #           layer: 'hyb'
          #       ]
          #   ]
          # ,
          #   title: 'ArcGIS (REST)'
          #   type: 'base'
          #   source: [
          #     'TileArcGISRest'
          #       url: 'https://tiles.arcgis.com/tiles/1KSVSmnHT2Lw9ea6/arcgis/rest/services/' +
          #         'basemap_stadsplan_v6/MapServer'
          #   ]
          # ,
            _layerId: 'arcgis_xyz_base'
            title: 'ArcGIS (XYZ)'
            type: 'base'
            source: [
              'XYZ'
                url: 'https://tiles.arcgis.com/tiles/1KSVSmnHT2Lw9ea6/arcgis/rest/services/' +
                  'basemap_stadsplan_v6/MapServer/tile/{z}/{y}/{x}'
            ]
          ,
            _layerId: 'arcgis_xyz_politie_base'
            title: 'ArcGIS Pol (XYZ)'
            type: 'base'
            source: [
              'XYZ'
                url: 'http://app10.p.politie.local/arcgissql/rest/services/' +
                  'Publiek/Basemap_wgs84_g/MapServer/tile/{z}/{y}/{x}'
            ]
          ,
            _layerId: 'arcgis_rest_antwerp_base'
            title: 'Antwerp Geodata (ArcGIS, REST)'
            type: 'base'
            source: [
              'TileArcGISRest'
                projection: 'EPSG:31370'
                url: 'http://geodata.antwerpen.be/arcgissql/rest/services/' +
                  'P_Publiek/P_basemap/MapServer'
            ]
          ,
            _layerId: 'bing_road'
            title: 'Bing (Road)'
            type: 'base'
            source: [
              'BingMaps'
                imagerySet: 'Road'
            ]
          ,
            _layerId: 'bing_aerial_with_labels'
            title: 'Bing (Aerial + Labels)'
            type: 'base'
            source: [
              'BingMaps'
                imagerySet: 'AerialWithLabels'
            ]
          ,
            _layerId: 'arcgis_aerial_base'
            _weight: 60
            title: 'ArcGIS (Aerial)'
            type: 'base'
            source: [
              'XYZ'
                url: mundoConfiguration.oauth.baseUrl + '/api/lpa/proxy/aerial_layer/tile/{z}/{y}/{x}'
                tileLoadFunction: (tile, url) ->
                  $http(
                    method: 'GET'
                    url: url
                    withCredentials: false
                    responseType: "blob"
                    cache: true
                  ).then (data, status, headers, config) ->
                    fr = new FileReader()
                    fr.onload = () ->
                      tile.getImage().src = fr.result
                    fr.readAsDataURL(data.data)
                  , (data, status, headers) ->
                    $log.warn "Stuff doesn't work"
            ]
          ,
            _layerType: 'Vector'
            _layerId: 'anpr'
            _clusterable: false
            _searchable: false
            _zoomable: false
            _updateWhileMoving: true
            _serviceUrl: mundoConfiguration.oauth.baseUrl + '/api/lpa/proxy/anpr_layer'
            _format: 'EsriJSON'
            title: 'ANPR'
            visible: true
            source: [
              'Vector'
                features: []
            ],
            style: null
          ,
            _layerType: 'Vector'
            _layerId: 'defense'
            _clusterable: false
            _searchable: false
            _zoomable: true
            _updateWhileMoving: true
            title: 'Defensie'
            visible: false
            source: [
              'Vector'
                features: []
            ],
            style: @defenseStyleFunction
          ,
            _layerType: 'Vector'
            _layerId: 'routes'
            _clusterable: false
            _searchable: false
            _zoomable: true
            _updateWhileMoving: true
            title: 'Routes (Base)'
            visible: true
            source: [
              'Vector'
                features: []
            ],
            style: null
          ,
            _layerType: 'Vector'
            _layerId: 'trails'
            _clusterable: false
            _searchable: false
            _zoomable: true
            _updateWhileMoving: true
            title: 'Trails (Base)'
            visible: true
            source: [
              'Vector'
                features: []
            ],
            style: null
          ,
            _layerType: 'Vector'
            _layerId: 'incidents'
            _clusterable: 10
            _searchable: true
            _zoomable: true
            _updateWhileMoving: true
            title: 'Incidents (Base)'
            visible: true
            source: [
              'Vector'
                features: []
            ],
            style: null
          ,
            _layerType: 'Vector'
            _layerId: 'markers'
            _clusterable: true
            _searchable: true
            _zoomable: true
            _updateWhileMoving: true
            title: 'Markers (Base)'
            visible: true
            source: [
              'Vector'
                features: []
            ],
            style: null
          ,
            _layerType: 'Vector'
            _layerId: 'poiOrders'
            _clusterable: 10
            _searchable: true
            _zoomable: true
            _updateWhileMoving: true
            title: 'Poi Orders (Base)'
            visible: true
            source: [
              'Vector'
              features: []
            ],
            style: null
        ]
        view:
          center: [4.402616, 51.216238]
          maxZoom: 20
          zoom: 13
        controls: [
          ['Zoom']
          ['ZoomSlider']
          ['ScaleLine']
          ['LayerSwitcher']
        ]
        follow:
          objects: {}
          enabled: false
          speedZoom: false
          zoomLevel: 18
        fillScreen: true
        search:
          enabled: true
          events:
            onSearchQueryExecute: () ->
            onTagSearchQueryExecute: () ->
            onAddressSearchQueryExecute: () ->
            onSearchQueryExecuted: (map, query, results) ->
              map._mundoMapOptions.follow.objects = results

              if results.length
                map
                  ._mundoMap
                  .panToFeatures map, results
          tagRules: [
            (t) -> /^\s*[^\s]+\s*$/.test t
          ]
        BingMapsApiKey: null

      MundoMapBase.createLayerFromConfig = (mapOptions, layerOptions) ->
        layer = null
        layerOptions = angular.merge {}, @layerDefaults, layerOptions

        if layerOptions._layerType == 'Group'
          layers = []

          for subLayerOptions in layerOptions.layers
            layers.push (@createLayerFromConfig mapOptions, subLayerOptions)

          layerOptions.layers = layers
        else
          if layerOptions.source[0] == 'BingMaps'
            layerOptions.source[1] ?= {}
            layerOptions.source[1].key ?= mapOptions.BingMapsApiKey

            if not layerOptions.source[1].key?
              $log.warn 'Map: Could not create BingMaps layer due to missing API key'
              return null

          layerOptions.source = new ol.source[layerOptions.source[0]] (layerOptions.source[1] or {})

          if layerOptions._layerType == 'Vector'
            layerOptions.style ?= @styleDefaults

            if layerOptions._clusterable
              distance = if layerOptions._clusterable == true then 15 else layerOptions._clusterable
              layerOptions._clusterable = true

              layerOptions.style = @clusterStyleDefaults
              layerOptions.source = new ol.source.Cluster
                source: layerOptions.source
                distance: distance

            if layerOptions._updateWhileMoving
              layerOptions.updateWhileAnimating = true
              layerOptions.updateWhileInteracting = true

            if layerOptions._layerId == "defense"
              folder = '/resources/geojson/defensie/'

              defenseSource = new ol.source.Vector
                url: folder + 'BUITENSECTOR.geojson'
                format: new ol.format.GeoJSON()

              layerOptions.source = defenseSource
              layerOptions.style = @defenseStyleFunction

            if layerOptions._format == "EsriJSON"

              layerOptions.style = MundoMapBase.cameraStyle

              anprSource = new ol.source.Vector
                strategy: ol.loadingstrategy.all
                loader: (extent, resolution, projection) ->
                  url = layerOptions._serviceUrl + '/query'
                  $http(
                    method: 'GET'
                    url: url
                    withCredentials: false
                    headers:
                      # Authorization: undefined
                      'Content-Type': 'application/json'
                    params:
                      'where':'0=0'
                      'geometryType':'esriGeometryEnvelope'
                      'spatialRel':'esriSpatialRelIntersects'
                      'outFields':'*'
                      'returnGeometry':'true'
                      'returnIdsOnly':'false'
                      'returnCountOnly':'false'
                      'returnZ':'false'
                      'returnM':'false'
                      'returnDistinctValues':'false'
                      'f':'json'
                  ).success (data, status, headers, config) ->
                    features = MundoMapBase.getGeometryFromEsriJSON(data)
                    anprSource.addFeatures features
                  .error (data, status, headers) ->
                    $log.warn "Stuff doesn't work"

              layerOptions.source = anprSource

        layer = new ol.layer[layerOptions._layerType] layerOptions

        return layer

      MundoMapBase.getGeometryFromGeoJSON = (geoJson) ->
        geometry = @GeoJSON
          .readGeometry geoJson,
            # dataProjection: 'EPSG:4326'
            featureProjection: 'EPSG:3857'

      MundoMapBase.getGeometryFromEsriJSON = (EsriJSON) ->
        EsriJSONFormat = new ol.format.EsriJSON()
        geometry = EsriJSONFormat.readFeatures EsriJSON,
          featureProjection: 'EPSG:3857'
        geometry

      MundoMapBase.getReverseGeocoding = (lon, lat, lang) ->
        Restangular.all("services/reverse-geocode").customGET("", { lat: lat, lon: lon, lang: lang })
          .then (response) ->
            response
      
      MundoMapBase.getGeocode = (address) ->
        Restangular.all('services/geocode').post({ address })
          .then (response) ->
            response

      MundoMapBase.getLocationFromGeocoding = (lon, lat, lang) ->
        geocoding = MundoMapBase.getReverseGeocoding lon, lat, lang
        geocoding.then (data) ->
          MundoMapBase.buildLocationDescription data.address

      MundoMapBase.buildLocationDescription = (address) ->
        locationDescription = ''

        locationDescription += (address.house_number + ' ') if address.house_number?
        locationDescription += (address.road + ', ')        if address.road?
        locationDescription += (address.pedestrian + ', ')  if address.pedestrian? && !address.road?
        locationDescription += (address.postcode + ' ')     if address.postcode?
        locationDescription += (address.city + ', ')        if address.city?
        locationDescription += address.country              if address.country?

      MundoMapBase.getLayersByPropertyValue = (map, property, value) ->
        results = map
          .getLayers()
          .getArray()
          .filter (v) ->
            v.get(property) == value

        return results

      MundoMapBase.getLayerById = (map, layerId) ->
        results = @getLayersByPropertyValue map, '_layerId', layerId

        return results[0] or null

      MundoMapBase.getVisibleLayerIds = (map) ->
        visibleLayerIds = []
        angular.forEach map.getLayers(), (mapLayer) ->
          if mapLayer.getVisible()
            visibleLayerIds.push (mapLayer.get '_layerId')
        return visibleLayerIds

      MundoMapBase.setVisibleLayers = (map, layerIds) ->
        angular.forEach map.getLayers(), (mapLayer) ->
          if layerIds.indexOf(mapLayer.get '_layerId') > -1
            mapLayer.setVisible true
          else
            mapLayer.setVisible false

      MundoMapBase.getLayerSource = (layer) ->
        if layer
          if layer.get('_clusterable') == true
            return layer.getSource().getSource()
          return layer.getSource()
        return layer

      MundoMapBase.zoomToExtent = (map, extent) ->
        if map._mundoMapOptions.follow.enabled
          map
            .getView()
            .fit extent, map.getSize(),
              padding: [100, 100, 100, 100]
              constrainResolution: true

      MundoMapBase.zoomToLocation = (map, location, maxZoom) ->
        geometry = MundoMapBase.getGeometryFromGeoJSON location
        
        extent = ol.extent.createEmpty()

        ol.extent.extend extent, geometry.getExtent()

        pan = ol.animation.pan
          duration: 400
          source: map.getView().getCenter()

        zoom = ol.animation.zoom
          duration: 400
          resolution: map.getView().getResolution()

        map.beforeRender(pan)
        map.beforeRender(zoom)
        map
          .getView()
          .fit extent, map.getSize(),
            padding: [100, 100, 100, 100]
            constrainResolution: true
            maxZoom: maxZoom

      MundoMapBase.disableFollow = (map) ->
        map._mundoMapOptions.follow.enabled = false

      MundoMapBase.panToFeatures = (map, features) ->
        if not features.length
          return

        extent = ol.extent.createEmpty()

        for feature in features
          ol.extent.extend extent, feature.getGeometry().getExtent()

        @zoomToExtent map, extent

      MundoMapBase.speedZoom = (map, feature) ->
        if not map._mundoMapOptions.follow.speedZoom.enabled
          returns

      MundoMapBase.autoFit = (map) ->
        if not map._mundoMapOptions.follow.enabled
          return

        layers = @getLayersByPropertyValue map, '_zoomable', true

        if not layers.length
          return

        extent = ol.extent.createEmpty()

        for layer in layers
          ol.extent.extend extent, layer.getSource().getExtent()

        if extent[0] == Infinity
          return

        @zoomToExtent map, extent

      MundoMapBase.executeTagSearchQuery = (map, query, features) ->
        map._mundoMapOptions.search.events.onTagSearchQueryExecute map, query
        $log.debug 'Map: Executing tag search => query: ', query

        if not features?
          features = @getLayersByPropertyValue map, '_searchable', true
            .map (layer) =>
              return @getLayerSource layer
                .getFeatures()
          features = _.union.apply _.union, features

        results = features
          .filter (feature) ->
            if feature.get('_tags')?
              return feature.get('_tags')
                .filter (tag) ->
                  tag = tag.toLowerCase()
                  query = query.toLowerCase()
                  return tag.indexOf(query) > -1
                .length

            return false

        map._mundoMapOptions.search.events.onSearchQueryExecuted map, query, results
        $log.debug "Map: Executed tag search => query: #{query}, results: ", results

        return results

      MundoMapBase.executeAddressSearchQuery = (map, query) ->
        map._mundoMapOptions.search.events.onAddressSearchQueryExecute map, query
        $log.debug 'Map: Executing address search => query: ', query

        # @TODO Make a Geocoder service that uses Nominatim, JSONP, some other magic
        # to determine coordinates for a given address string and returns
        # a feature and/or geometry object
        results = []

        map._mundoMapOptions.search.events.onSearchQueryExecuted map, query, results
        $log.debug "Map: Executed address search => query: #{query}, results: ", results

      MundoMapBase.executeSearchQuery = (map, query) ->
        query = query.trim()

        if query
          map._mundoMapOptions.search.events.onSearchQueryExecute map, query

          for rule in map._mundoMapOptions.search.tagRules
            if rule query
              return @executeTagSearchQuery map, query

          @executeAddressSearchQuery map, query
        else
          map._mundoMapOptions.follow.objects = []
          @autoFit map

      MundoMapBase.setZoomLevel = (map, zoomLevel) ->

        map._options.follow.zoomLevel = zoomLevel
        map.getView().setZoom zoomLevel

      MundoMapBase.setSpeedZoom = (map, state) ->
        map._options.follow.speedZoom = state

      MundoMapBase.setFollow = (map, state) ->
        map._options.follow.enabled = state

      MundoMapBase.mapSpeedToZoomLevel = (speed) ->
        if speed <= 10
          19
        else if speed <= 30
          18
        else if speed <= 50
          17
        else if speed <= 90
          16
        else if speed <= 150
          15
        else
          14

      MundoMapBase.updateMapView = (map) ->
        if map._options.follow.enabled
          if Object.keys(map._options.follow.objects).length
            if Object.keys(map._options.follow.objects).length is 1
              # Set the zoom level based on the speed of the unit
              angular.forEach map._options.follow.objects, (feature) =>
                map.getView().setCenter feature.getGeometry().getCoordinates()
                if map._options.follow.speedZoom is true
                  if (feature.get '_status').location.gps.speed
                    @setZoomLevel map, @mapSpeedToZoomLevel (feature.get '_status').location.gps.speed
                  else
                    @setZoomLevel map, map._options.follow.zoomLevel
          else
            # FIT ALL THE THINGS
            @autoFit map

      MundoMapBase.getMarkerPath = (objectId) ->
        "#{Restangular.configuration.baseUrl}/open/units/markers/#{objectId}"

      MundoMapBase.cameraStyle = (feature) ->
        style = null
        bgColor = '#0A94D6'
        textColor = '#FFFFFF'
        iconColor = '#00368b'

        if not style
          style = [
            new ol.style.Style
              image: new ol.style.Icon
                #/** @type {olx.style.IconOptions} */
                anchor: [0.5, 0.5]
                anchorXUnits: 'fraction'
                anchorYUnits: 'fraction'
                opacity: 0.85
                size: [400,400]
                imgSize: [400,400]
                scale: .04
                src: "/images/map-anpr/camera.png"
                color: iconColor
          ]

        return style

      # Combine color and opacity in one for styling
      MundoMapBase.convertHexToRGBA = (color, opacity) ->
        color = ol.color.asArray(color)
        color = color.slice()
        color[3] = opacity
        return color
      
      ## Custom theme for layers from geoJSON files
      MundoMapBase.geoJSONStyles = (feature, strokeColor, fillColor, width, circleColor) ->
        'Point': new ol.style.Style
          image: new ol.style.Circle
            radius: 5
            fill: new ol.style.Fill
              color: circleColor
            stroke: new ol.style.Stroke
              color: 'black'
          ###
          text: new ol.style.Text
            text: feature.get('name')
            scale: 1.4
            offsetY: -20
            offsetX: 10
            fill: new ol.style.Fill
              color: 'white'
            stroke: new ol.style.Stroke
              color: 'black'
              width: 2
          ###
        'Polygon': new ol.style.Style
          stroke: new ol.style.Stroke
            color: strokeColor
            width: width
          fill: new ol.style.Fill
            color: fillColor
    
      MundoMapBase.defenseStyleFunction = (feature) ->
        type = feature.getGeometry().getType()
        # TODO generalize this in a different class if used with more geoJson's
        # because this is tailered for the geojson for Defensie
        strokeColorTemp = feature.get('stroke') or "#F9F9F9"
        strokeOpacity = feature.get('stroke-opacity') or 1
        strokeColor = MundoMapBase.convertHexToRGBA strokeColorTemp,strokeOpacity

        fillColorTemp = feature.get('fill') or "#F9F9F9"
        fillOpacity = feature.get('fill-opacity') or 0.1
        fillColor = MundoMapBase.convertHexToRGBA fillColorTemp,fillOpacity

        width = feature.get('stroke-width') or 2

        circleColor = "#F9F9F9"
        # Turns the images in geoJSON to colors
        switch feature.get('styleHash')
          when "3f22f126" then circleColor = "#ff5252"
          when "344c5f8d" then circleColor = "#ff5252"
          when "-3f2fd973" then circleColor = "#00e600"
          when "43430ad4" then circleColor = "#00e600"
          else circleColor = "#F9F9F9"

        style = MundoMapBase.geoJSONStyles(feature, strokeColor, fillColor, width, circleColor)[type]
        return style

      MundoMapBase.createInstance = (target, options = {}) ->
        options = angular.merge {}, @defaults, options
        controls = []
        layers = []
        view = null

        options.view.center = ol.proj.fromLonLat options.view.center
        view = new ol.View options.view

        for layer in options.layers
          result = (@createLayerFromConfig options, layer)

          if result?
            layers.push result

        for control in options.controls
          controls.push new ol.control[control[0]]

        map = new ol.Map
          target: target
          layers: layers
          controls: controls
          renderer: 'canvas'
          view: view
          loadTilesWhileAnimating: true
          loadTilesWhileInteracting: true

        map._options = options

        # Somehow this is neccesary for rendering the map in fullscreen
        $timeout ->
          map.updateSize()

        map._mundoMap = @
        map._mundoMapOptions = options

        if map._options.follow.enabled
          for layer in @getLayersByPropertyValue map, '_zoomable', true
            @getLayerSource layer
              .on 'change', (e) =>
                @updateMapView(map)

        handleSearchEvent = debounce 500, (event) =>
          query = event.target.value
          @executeSearchQuery map, query

        if map._options.search.enabled
          angular.element "##{target}"
            .addClass 'mundo-map'
            .append "<input class='map-search' type='text'>"
            .find '.map-search'
            .on 'keyup', handleSearchEvent
            .on 'paste', handleSearchEvent

        $log.debug "Map: Created a map instance => target: #{target}, options: ", options

        return map

      # Inject API keys
      if mundoConfiguration.map? && mundoConfiguration.map.BingMaps? &&
      mundoConfiguration.map.BingMaps.apiKey?
        MundoMapBase.defaults.BingMapsApiKey = mundoConfiguration.map.BingMaps.apiKey

      MundoMapBase
  ]
